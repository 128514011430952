/**
 * Default and not editable combinations from connect to teams
 * Used en in the back and frontend
 */
export const defaultTeamsIntegrationsStatusesConnectToTeams = [
  {
    statusConnectPath: "Available",
    statusTeams: "Available",
    editable: false,
  },
  {
    statusConnectPath: "Connected",
    statusTeams: "Busy",
    editable: false,
  },
  {
    statusConnectPath: "Offline",
    statusTeams: "Away",
    editable: false,
  },
];

/**
 * Default and not editable combinations from teams to connect
 * Used en in the back and frontend
 */
export const defaultTeamsIntegrationsStatusesTeamsToConnect = [
  {
    statusConnectPath: "Available",
    statusTeams: "Available",
    editable: false,
  },
];

/**
 * Availables statuses in teams api
 * Used frontend for visual mapping
 */
export const defaultMicrosoftTeamsStatuses = [
  {
    status: "Available",
    defaultLabel: "Available",
  },
  {
    status: "Away",
    defaultLabel: "Away",
    defaultConnectPathStatus: "Offline",
  },
  {
    status: "Busy",
    defaultLabel: "Busy",
  },
  {
    status: "DoNotDisturb",
    defaultLabel: "Do Not Disturb",
    defaultConnectPathStatus: "Offline",
  },
];

export const defaultMicrosoftTeamsStatusesNamesList = defaultMicrosoftTeamsStatuses.map((statusTeam) => statusTeam.status);

/**
 * Used actually in the backend to call microsoft api graph set status endpoint
 */
export const mappingTeamsStatuses = {
  Available: {
    availability: "Available",
    activity: "Available",
  },
  Busy: {
    availability: "Busy",
    activity: "InACall",
  },
  Away: {
    availability: "Away",
    activity: "Away",
  },
  DoNotDisturb: {
    availability: "DoNotDisturb",
    activity: "Presenting",
  },
  Default: {
    availability: "Away",
    activity: "Away",
  },
};

/**
 * Used actually in the backend to call set prefered status endpoint
 */
export const userPreferredPresenceActivity = {
  Available: "Available",
  Busy: "Busy",
  DoNotDisturb: "DoNotDisturb",
  BeRightBack: "BeRightBack",
  Away: "Away",
  Offline: "OffWork",
};

export const statusMicrosoftTeams = [
  "Available",
  "AvailableIdle",
  "Away",
  "BeRightBack",
  "Busy",
  "BusyIdle",
  "DoNotDisturb",
  "Offline",
  "PresenceUnknown",
];
