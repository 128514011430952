export const LIGHT = {
  MODE: "light",
  CLASS_THEME: "v-light-theme",
  LABEL: "#000000",
};

export const DARK = {
  MODE: "dark",
  BACKGROUND: "EBEEF1",
  CLASS_THEME: "dark-body",
  LABEL: "#f6f7f8",
};
