export class GraphQLError extends Error {
  errorCode;
  errorMessage;
  errorParams;

  constructor(errorCode, errorMessage, errorParams) {
    super(errorCode);

    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
    this.errorParams = errorParams || [];
  }
}
