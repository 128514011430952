import dateFns from "date-fns";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt";

export const getNewDateFormattedForFileNames = ({ language }) => {
  const date = new Date();
  if (language === "es") {
    return dateFns.format(date, "DDMMYYYY-HHmmss", { locale: es });
  }
  if (language === "pt") {
    return dateFns.format(date, "DDMMYYYY-HHmmss", { locale: pt });
  }
  return dateFns.format(date, "YYYYMMDD-HHmmss");
};
