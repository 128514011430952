export const CUSTOM_STACK_INTEGRATION_DEPLOY_STATUSES = {
  NotDeployed: "not-deployed",
  Deployed: "deployed",
  Outdated: "outdated",
  Deploying: "deploying",
  Deleting: "deleting",
  Error: "error",
};

export const CUSTOM_STACK_INTEGRATION_TEMPLATE_PARAMETER_TYPES = {
  Text: "text",
  Select: "select",
  Internal: "internal",
};

export const CUSTOM_STACK_INTEGRATION_TEMPLATE_PARAMETER_INTERNAL_TYPES = {
  Stage: "stage",
  InstanceId: "instanceId",
  InstanceAlias: "instanceAlias",
  InstanceRegion: "instanceRegion",
};

export const CUSTOM_STACK_INTEGRATION_ACTION_TYPES = {
  Deploy: "deploy",
  Delete: "delete",
  Detail: "detail",
};

export const CUSTOM_STACK_INTEGRATION_POST_DEPLOY_ACTIONS = {
  S3ToS3Sync: "S3ToS3Sync",
  CreateFileS3: "CreateFileS3",
  InvalidateCloudFront: "InvalidateCloudFront",
  EmptyS3: "EmptyS3",
};

export const CUSTOM_STACK_INTEGRATION_POST_DEPLOY_ACTIONS_SOURCES = {
  FromStackParameter: "fromStackParameter",
  FromOutputParameter: "fromOutputParameter",
  FromValue: "fromValue",
};

export const CUSTOM_STACK_INTEGRATION_POST_DEPLOY_CREDENTIALS_PROVIDERS = {
  None: "none",
  Customer: "customer",
};
