export const isValidJSON = (text) => {
  if (typeof text !== "string") {
    return false;
  }
  try {
    var json = JSON.parse(text);
    return typeof json === "object";
  } catch (error) {
    return false;
  }
};
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export function getClientDomain({ clientDomains, origin }) {
  const domains = clientDomains.split(",");

  if (origin) {
    for (const domain of domains) {
      if (origin.includes(domain)) {
        return domain;
      }
    }
  }

  return domains[0];
}
export function replaceQueryStringVariables({ queryString, stepResponseName, stepResponseData, appid }) {
  console.log(queryString);

  for (const param in queryString) {
    if (queryString[param].startsWith("{{") && queryString[param].endsWith("}}")) {
      if (queryString[param].includes(stepResponseName)) {
        const keyToSearchFromStepResponse = queryString[param].split(".")[1].slice(0, -2);
        queryString[param] = stepResponseData[keyToSearchFromStepResponse];
      } else if (queryString[param].includes("appid")) {
        queryString[param] = appid;
      }
    }
  }
  return queryString;
}
export function replaceODataVariables({ queryString, stepResponseName, stepResponseData }) {
  if (queryString.startsWith("(") && queryString.includes(")") && queryString.includes(stepResponseName)) {
    let keyValueToUseFromStepResponse = queryString.includes("?")
      ? queryString?.split("?")[0].split(".")[1].slice(0, -1)
      : queryString?.split(".")[1].slice(0, -1);
    let keyValueToBeReplaced = `${stepResponseName}.${keyValueToUseFromStepResponse}`;

    queryString = replaceAll({
      string: queryString,
      values: { [keyValueToBeReplaced]: stepResponseData[keyValueToUseFromStepResponse] },
    });
  }
  return queryString;
}
export function replaceRequestBodyVariables({ requestBody, stepResponseName, stepResponseData, contact }) {
  for (const param in requestBody) {
    if (requestBody[param].startsWith("{{") && requestBody[param].endsWith("}}")) {
      if (requestBody[param].includes(stepResponseName)) {
        const keyToSearchFromStepResponse = requestBody[param].split(".")[1].slice(0, -1);
        requestBody[param] = stepResponseData[keyToSearchFromStepResponse];
      }
      for (const attribute in contact) {
        if (requestBody[param].includes(attribute)) {
          requestBody[param] = contact[attribute];
        }
      }
    } else {
      requestBody[param] = replaceAll({
        string: requestBody[param],
        values: contact,
      });
    }
  }
  return requestBody;
}

export const replaceAll = ({ string, values }) => {
  const matched = new RegExp(Object.keys(values).join("|"), "gi");
  return string.replace(matched, (match) => {
    return values[match];
  });
};
