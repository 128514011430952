export const URL_CCP_LOG_PARSER = `https://www.connect-tools.net/ccp-log-parser/`;
export const AGGRID_LINCESE = `Using_this_AG_Grid_Enterprise_key_( AG-041824 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( CloudHesive )_is_granted_a_( Single Application )_Developer_License_for_the_application_( ConnectPath )_only_for_( 3 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( ConnectPath )_need_to_be_licensed___( ConnectPath )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 29 May 2024 )____[v2]_MTcxNjkzNzIwMDAwMA==636bd9f08ea5b3936289c6943427081e`;
export const UPDATE_STACK = `updateStack`;
export const INTEGRATION_ACTIONS = {
  ADD: `add`,
  EDIT: `edit`,
  FINISH: `finish`,
  TEST: `test`,
  DELETE: `delete`,
  ERROR: `error`,
};
export const STEPS = {
  INITIAL: 0,
  FIRST: 1,
};
export const TYPES = {
  STRING: `string`,
  FUNCTION: `function`,
};
export const CHANNELS = {
  CHAT: `chat`,
  TASK: `task`,
  PHONE: `phone`,
  VIDEO: `video`,
};

export const TIME = {
  AM: "AM",
  PM: "PM",
};
export const NOT_APPLICABLE = "N/A";
export const HOURS_TO_EXPIRE_SESSION = 12;

export const PERMISSION_GRANTED = "granted";
export const IDLE_DETECTOR = "IdleDetector";
export const IDLE_STATE = "idle";
export const LOCKED_SCREEN = "locked";

export const MAX_INTEGRATIONS_PER_QUEUE = 5;

export const VIDEO_CONTROLS = {
  SHARE: "share",
  MIC: "mic",
  AUDIO: "audio",
  VIDEO: "video",
  END: "end",
  DEFAULT_TILE_ID: 1,
};

export const CUSTOM_LINKS = {
  GLOBAL_SCOPE: "Global",
  PRIVATE_SCOPE: "Private",
  DEFAULT_SCOPE: "Global",
};

export const AGGRID_PAGINATION_PAGE_SIZE = 100;
export const AGGRID_PAGINATION_PAGE_SIZE_SELECTOR = [25, 50, 100, 250];
export const AGGRID_ROW_GROUP_PANEL_SHOW = "always";
export const AGGRID_GROUP_DISPLAY_TYPE = "groupRows";
export const AGGRID_AUTO_GROUP_COLUMN_DEF_CELL_RENDERER_PARAMS_INNER_RENDERER = "groupColumnCellRenderer";
export const AGGRID_AUTO_GROUP_COLUMN_DEF_PINNED = "left";
export const AGGRID_THEME_ALPINE_DARK = "ag-theme-alpine-dark";
export const AGGRID_THEME_ALPINE = "ag-theme-alpine";
